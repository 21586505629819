<script>
import { addClass } from '@/helpers/text-editor-helper.js';

export default {
  props: {
    block: {
      type: Object,
      required: true,
    },
  },
  computed: {
    categoryTitle() {
      return this.block.fields.CategoryTitle;
    },
    title() {
      return this.block.fields.Title;
    },
    heading() {
      return this.block.fields.HeadingLevel?.value || 'h2';
    },
    intro() {
      return addClass(this.block.fields.Intro, 'split-image-intro');
    },
    image() {
      return this.$toLitiumMediaUrl(this.block.fields.Image?.id, { maxWidth: 750 });
    },
    mediaAlignmentClass() {
      const alignment = this.block.fields.DesktopMediaAlignment?.value;
      if (alignment === 'right') return false;

      return `split-image-intro__container-${alignment}`;
    },
  },
};
</script>

<template>
  <div :class="['split-image-intro__container', mediaAlignmentClass]">
    <div class="split-image-intro__text-container">
      <div class="split-image-intro__title-container">
        <div class="split-image-intro__category-title">
          {{categoryTitle}}
        </div>
        <component :is="heading" v-if="title" class="split-image-intro__title">
          {{title}}
        </component>
      </div>
      <div class="split-image-intro__text-intro text-editor-content" v-html="intro"></div>
    </div>
    <div class="split-image-intro__image-container">
      <div class="split-image-intro__image-spacer"></div>
      <img class="split-image-intro__image" :src="image" />
    </div>
  </div>
</template>

<style>
  .split-image-intro__container {
    max-width: calc(var(--header-max-width) + 20px);
    margin: auto;
    padding: 20px 10px;
  }

  .split-image-intro__category-title {
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 4px;
    text-transform: uppercase;
  }

  .split-image-intro__title {
    margin: 0;
    text-align: left;
    color: var(--color-black);
  }

  .split-image-intro__text-intro {
    margin-top: 34px;
    margin-bottom: 30px;
    line-height: 24px;
    color: var(--color-black);
  }

  .split-image-intro__image-container {
    width: 100%;
    min-width: 300px;
    position: relative;
  }

  .split-image-intro__image-spacer {
    padding-top: 120%;
  }

  .split-image-intro__image {
    object-fit: cover;
    object-position: center;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
  }

  @media (--tabletAndDesktop) {
    .split-image-intro__container {
      display: flex;
      justify-content: space-between;
      padding: 20px 0;
    }

    .split-image-intro__container-left {
      flex-direction: row-reverse;
    }

    .split-image-intro__image-container {
      width: 385px;
      margin: 0 10px;
    }

    .split-image-intro__image-spacer {
      padding-top: 131.5%;
    }

    .split-image-intro__text-container {
      display: flex;
      flex-direction: column;
      padding: 0 10px;
      max-width: 604px;
    }

    .split-image-intro__category-title {
      margin-bottom: 10px;
    }

    .split-image-intro__text-intro {
      margin-top: auto;
      line-height: 22px;
      font-size: 14px;
      margin-bottom: 0;
    }
  }
</style>
